<template>
    <div class="container-fluid main-color">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <router-link :to="{
                            name: 'module-deactivate',
                        }">
                            <button type="button" class="btn btn-sm btn-blue waves-effect waves-light mb-2">
                                <i class="mdi mdi-arrow-left"></i>
                                Back
                            </button>
                        </router-link>
                    </div>
                    <h4 class="page-title">Module Deactivation Management</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-10">
                <place-holder v-if="loading"></place-holder>
                <div class="card">
                    <div v-if="!loading" class="card-body">
                        <form class="parsley-examples">
                            <div class="row mb-3">
                                <label for="name" class="col-md-3 form-label">
                                    Module Name
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="name" name="name" class="form-control" placeholder="Module Name"
                                        v-model="module_deactivate.name" disabled />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="issued_date" class="col-md-3 form-label">
                                    Issued Date
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="date" name="date" class="form-control" placeholder="Issued Date"
                                        v-model="module_deactivate.issued_date" disabled />
                                </div>
                            </div>
                            <div class="row mb-3">
                                <label for="requested" class="col-md-3 form-label">
                                    Requested By
                                </label>
                                <div class="col-md-7">
                                    <input type="text" id="requested" name="requested" class="form-control"
                                        placeholder="Requested By" v-model="module_deactivate.requested_by" disabled />
                                </div>
                            </div>

                            <div class="row mb-3">
                                <label for="reason" class="col-md-3 form-label">
                                    Reason
                                </label>
                                <div class="col-md-7">
                                    <textarea name="reason" id="reason" rows="2" placeholder="Reason"
                                        v-model="module_deactivate.reason" class="form-control" disabled></textarea>
                                </div>
                            </div>
                            <!-- end row -->
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="text-center mt-3 mb-3 d-flex justify-content-center">
                                        <div>
                                            <!-- <router-link v-if="deactivate_status == null || deactivate_status == ''"
                                                :to="{ name: 'module-deactivate' }"> -->
                                            <!-- <button type="button"
                                                    class="btn w-sm btn-success waves-effect waves-light me-5"
                                                    @click="updateModuleDeactivate(status = 1)">
                                                    Approved
                                                </button> -->
                                            <button v-if="deactivate_status == 1 || deactivate_status == 2" type="button"
                                                style="cursor: not-allowed"
                                                class="btn w-sm btn-success waves-effect waves-light me-5">
                                                Approved
                                            </button>
                                            <button type="button" v-else
                                                class="btn w-sm btn-success waves-effect waves-light me-5"
                                                @click="showModal(status = 1)">
                                                Approved
                                            </button>
                                            <!-- </router-link> -->
                                        </div>
                                        <div>
                                            <!-- <router-link v-if="deactivate_status == null || deactivate_status == ''"
                                                :to="{ name: 'module-deactivate' }"> -->
                                            <!-- <button type="button" class="btn w-sm btn-danger px-3"
                                                    @click="updateModuleDeactivate(status = 0)">
                                                    Rejected
                                                </button> -->
                                            <button v-if="deactivate_status == 1 || deactivate_status == 2" type="button"
                                                style="cursor: not-allowed" class="btn w-sm btn-danger px-3">
                                                Rejected
                                            </button>
                                            <button type="button" v-else class="btn w-sm btn-danger px-3"
                                                @click="showModal(status = 2)">
                                                Rejected
                                            </button>
                                            <!-- </router-link> -->
                                        </div>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <EnrollModal :modalId="'confirm-modal'">
        <template v-slot:header> Confirmation </template>
        <template v-slot:body>
            <div class="text-center" v-if="!loading">
                <p v-if="status == 1" class="text-center fs-4"><span class="text-success"> Are you Approved ? </span></p>
                <p v-else class="text-center fs-4"><span class="text-danger"> Are you Rejected ? </span></p>
            </div>
        </template>
        <template v-slot:footer>
            <button type="button" class="btn w-sm btn-secondary me-4" data-bs-dismiss="modal">
                Close
            </button>
            <button :disabled="isLoading ? true : false" type="button" class="btn w-sm btn-success waves-effect waves-light"
                @click="updateModuleDeactivate(status)">
                <span v-if="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                {{ isLoading == true ? 'Loading...' : 'Confirm' }}
            </button>
        </template>
    </EnrollModal>
</template>
  
<script>
import $ from "jquery";
import axios from "axios";
import { useToast } from "vue-toastification";
import EnrollModal from "../../components/shared/Modal.vue";

export default {
    setup() {
        const toast = useToast();
        return { toast };
    },
    components: {
        EnrollModal,
    },
    data() {
        return {
            module_deactivate: {
                module_id: "",
                name: "",
                issued_date: "",
                requested_by: "",
                reason: "",
            },
            deactivate_status: "",
            status: "",
            baseUrl: process.env.VUE_APP_BASE_URL,
            loading: false,
            isLoading: false
        };
    },
    methods: {
        async getModuleDeactivationById() {
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v1/module-deactivation-detail/${this.$route.params.id}`)
                .then((response) => {
                    const result = response.data.data;
                    this.module_deactivate = result;
                    this.module_deactivate.module_id = result.module_id;
                    this.module_deactivate.name = result.name;
                    this.module_deactivate.issued_date = result.issued_date;
                    this.module_deactivate.requested_by = result.requested_by.name;
                    this.module_deactivate.reason = result.reason;
                    this.deactivate_status = result.deactivate_status;
                })
                .catch(() => {
                    this.toast.error("Not Found Module!");
                });
            this.loading = false;
        },

        showModal(status) {
            console.log(status);
            $("#confirm-modal").modal("show");
        },

        async updateModuleDeactivate(status) {
            let updateForm = new FormData();
            updateForm.append("module_id", this.module_deactivate.module_id);
            updateForm.append("approved_or_rejected_by", this.$store.state.auth.user.employee_id);
            updateForm.append("status", status);
            this.isLoading = true;
            axios
                .post(
                    `${this.baseUrl}admin/v1/module-deactivation-approve-reject/${this.$route.params.id}`,
                    updateForm,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                )
                .then(() => {
                    $("#confirm-modal").modal("hide");
                    this.$router.push({ name: "module-deactivate" });
                    this.toast.success("Successfully Changed Status!");
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },
    },
    created() {
        this.getModuleDeactivationById();
    },
};
</script>

<style>
.form-control:disabled,
.form-control[readonly] {
    background-color: #efefef;
    opacity: 1;
    cursor: not-allowed;
}
</style>